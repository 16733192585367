<template>
  <drag-ranging-chebur
    :values="values"
    :content="content"
    :error="showError"
    @change="setData"
  />
</template>

<script>
import Validatable from '@/mixins/validatable'
import DragRangingChebur from '@components/features/DragRaging/DragRangingChebur'

/**
 * Чебурашка
 */
export default {
  name: 'ProcessingInputLeftright',

  components: {
    DragRangingChebur
  },

  inject: [
    'sendData',
    'service'
  ],

  mixins: [Validatable],

  props: {
    content: Object
  },

  computed: {
    showError () {
      return this.service.error.isShow && !this.isValid
    },
    values () {
      const directionMap = {
        l: 0,
        r: 1
      }

      return this.sendData[this.content.code]?.reduce((result, current) => {
        const [key, direction] = current.split(';')

        if (direction !== 'm') {
          result[directionMap[direction]].push(key)
        }

        return result
      }, [[], []]) || [[], []]
    }
  },

  mounted () {
    this.service.error.text = this.$t('processing.input.leftRight.error.matchAllAnswers')
    this.checkValidation()
  },

  methods: {
    setData (dndData, middleData) {
      const [leftData, rightData] = dndData
      const sendData = []

      leftData.forEach(item => sendData.push(`${item.code};l`))
      middleData.forEach(item => sendData.push(`${item.code};m`))
      rightData.forEach(item => sendData.push(`${item.code};r`))

      this.$set(this.sendData, this.content.code, sendData)
      this.checkValidation()
    },

    checkValidation () {
      const [leftData, rightData] = this.values

      const isAnswerDone = leftData.length > 0 || rightData.length > 0
      const minValidation = leftData.length >= this.content.l_min && rightData.length >= this.content.r_min
      const maxValidation = leftData.length <= this.content.l_max && rightData.length <= this.content.r_max

      this.checkValidationByCondition(isAnswerDone && minValidation && maxValidation)
    }
  }
}
</script>
